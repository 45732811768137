import { MutationHookOptions, gql, useMutation } from '@apollo/client'
import { Mutation, MutationCreateTiersArgs, MutationUpdateTierRulesArgs, MutationUpdateTiersArgs } from '../gen/models'

export const TierFragment = gql`
  fragment TierFragment on Tier {
    id
    name
    customerId
    booleanOperator
    discountValue
    discountMetric
    discountPeriod
  }
`

export const TierRebateFragment = gql`
  fragment TierRebateFragment on TierRebate {
    id
    name
    description
    type
    periodType
    scope
    value
    baselineSpend
    productTsIds
    vendors
    effectiveDate
    expirationDate
    metricType
    createdAt
    updatedAt
    advancedRulesJsonLogic
    contractsTiersRebates {
      tiersId
    }
  }
`

export const TierRuleFragment = gql`
  fragment TierRuleFragment on TierRule {
    id
    ruleType
    metricType
    periodType
    operator
    valueFirst
    valueSecond
    scope
    basketIds
    vendors
  }
`

const CREATE_TIER = gql`
  mutation createTiers($input: [CreateTierInput!]!) {
    createTiers(input: $input) {
      ...TierFragment
    }
  }
  ${TierFragment}
`

export function useCreateTierMutation(
  options?: MutationHookOptions<Pick<Mutation, 'createTiers'>, MutationCreateTiersArgs>,
) {
  return useMutation<Pick<Mutation, 'createTiers'>, MutationCreateTiersArgs>(CREATE_TIER, options)
}

const UPDATE_TIER = gql`
  mutation updateTiers($input: [UpdateTierInput!]!) {
    updateTiers(input: $input) {
      ...TierFragment
    }
  }
  ${TierFragment}
`

export function useUpdateTierMutation(
  options?: MutationHookOptions<Pick<Mutation, 'updateTiers'>, MutationUpdateTiersArgs>,
) {
  return useMutation<Pick<Mutation, 'updateTiers'>, MutationUpdateTiersArgs>(UPDATE_TIER, options)
}

const UPDATE_TIER_RULES = gql`
  mutation updateTierRules($input: UpdateTierRuleInput!) {
    updateTierRules(input: $input) {
      ...TierFragment
      rules {
        ...TierRuleFragment
      }
    }
  }
  ${TierFragment}
  ${TierRuleFragment}
`

export function useUpdateTierRulesMutation(
  options?: MutationHookOptions<Pick<Mutation, 'updateTierRules'>, MutationUpdateTierRulesArgs>,
) {
  return useMutation<Pick<Mutation, 'updateTierRules'>, MutationUpdateTierRulesArgs>(UPDATE_TIER_RULES, options)
}
